@import './variables.scss';

.ant-layout-sider-children .ant-menu {
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    background-color: $green;
    span,
    a {
      color: $white;
    }
  }
  .ant-menu-item-selected {
    background-color: $green;
    span,
    a {
      color: $gold;
      font-weight: bold;
    }
  }
  .ant-menu-item-selected:hover {
    background-color: $green;
    span,
    a {
      color: $gold;
      font-weight: bold;
    }
  }
  .ant-menu-item::after {
    border-right: 3px solid $white;
  }
}
